.button {
  background-color: rgb(75, 116, 170);
  color : white;
  font-weight: 900;
  padding : 15px 25px;
  border : none;
  margin : 20px 0px;
  cursor: pointer;
}

.button:hover {
  filter: opacity(0.8);
}