@font-face {
    font-family: Lato;
    font-weight: 100;
    src: url('../assets/fonts/Lato-Thin.ttf');
}

@font-face {
    font-family: Lato;
    font-weight: 100;
    font-style: italic;
    src: url('../assets/fonts/Lato-ThinItalic.ttf');
}

@font-face {
    font-family: Lato;
    font-weight: 300;
    src: url('../assets/fonts/Lato-Light.ttf');
}

@font-face {
    font-family: Lato;
    font-weight: 300;
    font-style: italic;
    src: url('../assets/fonts/Lato-LightItalic.ttf');
}

@font-face {
    font-family: Lato;
    font-weight: 400;
    src: url('../assets/fonts/Lato-Regular.ttf');
}

@font-face {
    font-family: Lato;
    font-weight: 400;
    src: url('../assets/fonts/Lato-Regular.ttf');
}

@font-face {
    font-family: Lato;
    font-weight: 400;
    font-style: italic;
    src: url('../assets/fonts/Lato-Regular.ttf');
}

@font-face {
    font-family: Lato;
    font-weight: 700;
    src: url('../assets/fonts/Lato-Bold.ttf');
}

@font-face {
    font-family: Lato;
    font-weight: 700;
    font-style: italic;
    src: url('../assets/fonts/Lato-BoldItalic.ttf');
}

@font-face {
    font-family: Lato;
    font-weight: 900;
    src: url('../assets/fonts/Lato-Black.ttf');
}

@font-face {
    font-family: Lato;
    font-weight: 900;
    font-style: italic;
    src: url('../assets/fonts/Lato-Black.ttf');
}